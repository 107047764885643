@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400&display=swap');

.homepage {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 84px);
    flex-wrap: wrap;
    align-content: center;
}

.card {
    background: white;
    border-radius: 10px;
    cursor: pointer;
    padding-right: 1rem;
    padding-left: 1rem;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    color: rgb(97, 97, 97);
}

.card:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .28);
    color: #036ddf !important;
}

.card-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 300px;
}

.card-icon {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-title {
    font-size: 1.5rem;

}

.homepage-wrap {
    display: grid;
    position: relative;
    height: calc(100vh - 64px);
    place-items: center;
    grid-template-rows: 0.3fr 0.7fr 0.5fr;
}

.homepage-table-wrap {
    position: absolute;
    width: 1132px;
    bottom: 0;
}

.homepage-table {
    height: 128px;
    background: #363636;
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
    position: relative;
    z-index: 0;
}

.homepage-middle-wrap {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    justify-items: center;
    width: 100%;
    align-items: center;
}

.homepage-top-wrap {
    display: grid;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: end;
}

.homepage-side-wrap {
    position: relative;
    width: 320px;
    height: 320px;
    display: grid;
    justify-items: center;
}

.homepage-table-dataExp {
    left: 150px;
    top: -110px;
    position: absolute;
    z-index: 1;
    display: grid;
    gap: 1rem;
    justify-items: center;
    width: 150px;
}

.homepage-confluence {
    display: grid;
    position: absolute;
    top: -100px;
    gap: 1rem;
    justify-items: center;
}

.homepage-events {
    display: grid;
    position: absolute;
    bottom: -100px;
    gap: 1rem;
    justify-items: center;
}

.homepage-img-title {
    display: grid;
    gap: 1rem;
    justify-items: center;
}

.homepage-hover:hover {
    font-weight: 600;
    cursor: pointer;

}

.homepage-hover span {
    padding: 2px 7px;
}

.homepage-hover:hover span {
    background: #ffffff9f;
    border-radius: 5px;
    font-weight: 600;
}

.homepage-webapp-wrap {
    width: 800px;
    height: 500px;
}

.homepage-webapp-header {
    display: flex;
    justify-content: space-between;
    background: black;
    height: 30px;
    padding-left: 1rem;
    align-items: center;
    color: white;

}

.homepage--webapp-close {
    width: 50px;
    background: #FF5050;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.homepage-webapp-img-wrap {
    position: relative;
    width: 140px;
    transition: all 0.2s ease-out;
}

.homepage-webapp-img-title {
    position: absolute;
    top: 69px;
    left: 22px;
    font-family: 'Kalam', cursive;
    color: blue;
}

.homepage-webapp-img-container {
    justify-content: space-evenly;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    gap: 2rem;
    padding: 1rem;
}

.homepage-webapp-img-wrap:hover {
    scale: 1.1;
    cursor: pointer;
}

.homepage-webapp-footer {
    position: absolute;
    background: #c6c6c6;
    bottom: 0;
    width: 100%;
    font-size: 14px;
}

.homepage-table-webApp {
    left: 370px;
    top: -170px;
    position: absolute;
    z-index: 1;
}

.homepage-table-webApp span {
    position: absolute;
    left: 94px;
    top: 30px;
    width: 170px;
    color: white;
    text-align: center;
    padding: 2px;
}

.homepage-table-webApp:hover span {
    background: #ffffff9f;
    border-radius: 5px;
    font-weight: 600;
    color: rgb(51, 51, 51);
}

.homepage-table-webApp:hover {
    cursor: pointer;
}

.homepage-icon-container {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
}

.homepage-icon-wrap {
    position: absolute;
    bottom: 67px;
    left: 35px;
    width: 80%;
    height: 100px;
    overflow: hidden;
}

.homepage-icon-img {
    color: white;
    font-size: 24px;
    bottom: -95px;
    position: relative;
    transition: bottom 0.3s ease-in-out;
}

.homepage-table-webApp:hover .homepage-icon-container .homepage-icon-img:nth-child(1) {
    bottom: 0;
    transition-delay: 0s;
}

.homepage-table-webApp:hover .homepage-icon-container .homepage-icon-img:nth-child(2) {
    bottom: 0;
    transition-delay: 0.1s;
}

.homepage-table-webApp:hover .homepage-icon-container .homepage-icon-img:nth-child(3) {
    bottom: 0;
    transition-delay: 0.2s;
}

.homepage-table-webApp:hover .homepage-icon-container .homepage-icon-img:nth-child(4) {
    bottom: 0;
    transition-delay: 0.3s;
}

.homepage-product-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
}

/* Styles for the popup content */
.homepage-product-close {
    position: absolute;
    z-index: 99;
    right: 1rem;
    color: white;
    top: 1rem;
}

.homepage-dashboard-link {
    position: absolute;
    width: 200px;
    top: -70px;
    left: 215px;
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 0.5;
}

.homepage-dashboard-link:hover {
    opacity: 1;
}

.homepage-window-image {
    border-radius: 5px;
    border: 3px solid #555;
    outline: 3px solid #3c3c3c;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.homepage-table-pencup {
    width: 60px;
    left: 775px;
    top: -25px;
    position: absolute;
    z-index: 1;
    scale: 0.9;
}

.homepage-table-lamp {
    width: 170px;
    left: 820px;
    top: -150px;
    position: absolute;
    z-index: 1;
    scale: 0.9;
}

.react-calendar {
    width: 315px !important;
    max-width: 100%;
    background: #fefefe !important;
    font-family: inherit !important;
    line-height: 1.125em;
    border-radius: 5px;
    box-shadow: grey;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
    font-weight: 600 !important;
}

abbr[title] {
    text-decoration: none !important;
}

.react-calendar__navigation {
    margin-bottom: 0px !important;
    height: 35px !important;
}

.react-calendar__tile {
    padding: 7px 6px !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em !important;
}

.react-calendar__viewContainer {
    padding: 5px;
    padding-top: 0px;
}

.react-calendar__tile--now {
    background: #006edca1 !important;
    color: white !important;
}

.react-calendar__tile--active {
    background: none !important;
    color: inherit !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #75757569 !important;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 0.75em;
    border-bottom: 1px solid grey;
}

/* Responsive CSS */

@media (max-width: 1600px),
(max-height: 900px) {
    .homepage-img-title {
        scale: 0.8;
        transition: all 0.2s ease-out;
    }

    .homepage-img-title span {
        scale: 1.2;
        transition: all 0.2s ease-out;
    }

    .homepage-window-image {
        scale: 0.8;
        transition: all 0.2s ease-out;
    }

    .homepage-confluence {
        scale: 0.8;
        transition: all 0.2s ease-out;
    }

    .homepage-confluence span {
        scale: 1.2;
        transition: all 0.2s ease-out;
    }

    .homepage-events {
        scale: 0.9;
        bottom: -30px;
        transition: all 0.2s ease-out;
    }

    .react-calendar {
        width: 285px !important;
    }

}

@media (min-height: 1100px) and (min-width: 2000px) {
    .homepage-window-image {
        width: 750px;
        transition: all 0.2s ease-out;
    }

    .homepage-img-title {
        scale: 1.2;
        transition: all 0.2s ease-out;
    }


    .homepage-confluence {
        scale: 1.2;
        transition: all 0.2s ease-out;
    }


    .homepage-events {
        scale: 1.2;
        bottom: -200px;
        transition: all 0.2s ease-out;
    }

    .homepage-table-lamp {
        scale: 1;
    }

    .homepage-table-pencup {
        scale: 1;
    }
}

@media (max-width: 1300px),
(max-height: 750px) {
    .homepage-img-title {
        scale: 0.7;
    }

    .homepage-img-title span {
        scale: 1.3;
    }

    .homepage-window-image {
        display: none;
    }

    .homepage-confluence {
        scale: 0.7;
        top: -128px;
    }

    .homepage-confluence span {
        scale: 1.3;
    }

    .homepage-events {
        scale: 0.8;
        bottom: -22px;
    }

    .homepage-events span {
        scale: 1.1;
    }

    .homepage-middle-wrap {
        height: 270px;
        display: flex;
        justify-content: space-between;

    }

    .homepage-side-wrap {
        height: 270px;
    }

    .homepage-table-wrap {
        width: 800px;
    }

    .homepage-table-dataExp {
        scale: 0.7;
        left: 80px;
        top: -120px;
    }

    .homepage-table-dataExp span {
        scale: 1.3;
    }

    .homepage-table-webApp {
        left: 200px;
        scale: 0.8;
        top: -180px;
    }

    .homepage-table-webApp span {
        scale: 1.1;
    }

    .homepage-table {
        height: 80px;
    }

    .homepage-table-lamp {
        left: 545px;
        scale: 0.7;
    }

    .homepage-table-pencup {
        left: 535px;
        top: -43px;
        scale: 0.7;
    }
}