.h1 {
    padding: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.notice-default {
    font-family: 'Roboto Mono', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}


.notice-noTable h1 {
    border: #F56260 solid 1px;
}

.notice-noAccess h1 {
    color: #F56260;
}