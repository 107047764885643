.upload-file {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.upload-form-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.upload-file-form {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.upload-form-file-label {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 35px;
}

.upload-form-file-label input {
    display: none;
}

.upload-file-label {
    height: 100%;
    width: 460px;
    padding-left: 10px;
    border: 1px solid rgba(128, 128, 128, 0.315);
    box-sizing: border-box;
    border-right: none;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    box-shadow: -2px -1px 8px -2px rgba(202, 202, 202, 0.56) inset;
    -webkit-box-shadow: -2px -1px 8px -2px rgba(202, 202, 202, 0.56) inset;
    -moz-box-shadow: -2px -1px 8px -2px rgba(202, 202, 202, 0.56) inset;
}

.upload-file-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgba(128, 128, 128, 0.315);
    background-color: rgba(223, 223, 223, 0.692);
    border-radius: 0 5px 5px 0;
    height: 100%;
    box-sizing: border-box;
    width: 135px;
}

.upload-file-btn:hover {
    border: 1px solid rgba(128, 128, 128, 0.445);
    background-color: rgba(192, 192, 192, 0.781);
}

.error-file {
    border: 1px solid rgba(255, 15, 15, 0.671);
    color: red;
}

.upload-form-table {
    width: 550px;
    padding-top: 5px;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
}

.upload-form-table tbody {
    background-color: rgba(255, 255, 255, 0.692);
}

.upload-form-table thead {
    text-align: center;
    background-color: #387bff;
    color: white;
    border: 1px solid transparent;
}


.upload-form-table td {
    border: 1px solid #dfdfdf;
    padding: 10px 8px;
}

.upload-form-table th {

    padding: 8px;
}

.upload-file-container {
    width: 100%;
    height: 90%;
    display: flex;
    gap: 10%;
    margin-bottom: 1rem;
}

.upload-error-log {

    overflow-y: scroll;
    font-size: 13px;
    position: relative;
    background-color: rgba(223, 223, 223, 0.692);
    padding: 5px;
    height: 90%;
}

.upload-error-cont {
    box-sizing: border-box;
    margin-top: 1rem;
    height: 500px;
}

.upload-error-log code {
    font-family: monospace !important;
}


.upload-success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.upload-status-info {
    display: flex;
    font-size: 16px;
    width: 100%;
    flex-flow: row wrap;
    border-collapse: collapse;
    justify-content: center;
    align-items: center;
}

.upload-status-info span {
    width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-collapse: collapse;
}

.upload-status-info-header {
    border-bottom: 1px solid grey;
}

.upload-status-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 330px;
}

.download-table-link {
    color: #036ddf;
    text-decoration: none;
    box-sizing: border-box;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}



.download-table-link:hover {
    opacity: 1;
    background-color: transparent;
}

/* drag drop component css */
.Upload {
    width: 550px;
    height: 200px;
    border: 2px dashed #8a8a8ab0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 12px;
}

.Highlight {
    border-color: #036ddf;
    background-color: #036ddf0c;
}

.Upload:hover {
    border-color: #036ddf;
    background-color: #036ddf0c;
}

.drag-drop-text {
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drag-drop-text div {
    color: black;
    font-weight: 600;
    font-size: 18px;
}

.drag-drop-text span {
    color: inherit;
    font-size: 0.8rem;
}

.drag-highlight {
    color: #036ddf;
}

.drag-highlight div {
    color: #036ddf;
    font-weight: 600;
    font-size: 18px;
}

.status-wrapper {
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 0 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.status-wrapper .status-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.status-wrapper .status-success .status-message {
    display: flex;
    align-items: center;
}

.status-wrapper .status-success .status-message .status-icon {
    margin-right: 10px;
}

.status-wrapper .status-success .status-message .status-text {
    font-weight: bold;
}


.status-wrapper .status-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 300px;
    column-gap: 60px;
}

.status-wrapper .status-details .details-row {
    display: flex;
    justify-content: space-between;
}

.status-wrapper .status-details .details-row .details-label {
    font-weight: bold;
}

.table-upload-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1100px) {
    .status-wrapper .status-details {
        grid-template-columns: auto;
        width: inherit;
        gap: 5px;
    }


    .status-icon {
        display: none;
    }

    .upload-file-container {
        gap: 1rem;
    }

    .status-summary {
        font-size: 0.8rem;
    }

    .details-row {
        padding: 0 10px;
        font-size: 0.9rem;
    }

    .status-text {
        text-align: center;
        padding: 0 5px;
        font-size: 0.9rem;
    }

    .upload-form-table {
        width: 450px;
        font-size: 15px;
    }

    .upload-form-table th,
    td {
        font-size: 15px;
    }

    .upload-file-label {
        width: 360px;
    }
}