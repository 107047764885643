.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.popup {
  padding: 20px;
  background: #eee;
  border-radius: 5px;
  width: fit-content;
  max-width: 90%;
  position: relative;
  transition: all .5s ease-in-out;
  overflow-y: scroll;
  padding-right: 12px;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  /* font-family: Tahoma, Arial, sans-serif; */
  margin-bottom: 1rem;
  text-align: center;

}

.popup .close {
  position: absolute;
  top: 10px;
  right: 20px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #3333338c;
}

.popup .close:hover {
  cursor: pointer;
  color: #000;
}

.popup .content {
  overflow: auto;

}

.title {
  margin-right: 2rem;
  min-height: 20px;
}

/* 
@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
} */