@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-Bold.woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-BoldItalic.woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-Light.woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-LightItalic.woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-Medium.woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-MediumItalic.woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-Regular.woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Display';
    src: url('assets/fonts/JohnsonDisplay/JohnsonDisplay-RegularItalic.woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-Bold.woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-BoldItalic.woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-Light.woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-LightItalic.woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-Medium.woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-MediumItalic.woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-Regular.woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face { 
    font-family: 'Johnson Text';
    src: url('assets/fonts/JohnsonText/JohnsonText-RegularItalic.woff2');
    font-weight: 400;
    font-style: italic;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Johnson Text', 'Open Sans', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

body {
    background-color: #eee;
    font-family: 'Johnson Text', 'Open Sans', sans-serif;
}

h2 { 
    font-family: 'Johnson Display', 'Open Sans', sans-serif;
    font-weight: 500;
}

/* colors */
/* Color naming should increase from 1. (1 as main )*/
:root {
    --font-color-1: #212121;
    --bg-color-1: #ffffff;
    --link-color-1: #03a9f4;
    --green: #32a852;
    --blue: #387bff;
    --red: #df4747;
    --grey: #5f5f5f;
    --border-color-1: #c7c7c7c4;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 3px;
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.61);
    border-radius: 3px;
    display: block;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(138, 138, 138);
    display: block;
}

.app {
    padding-top: 64px;
}


.container {
    margin-left: 300px;
    transition: margin-left ease-in-out 0.5s;
    padding: 50px;
    box-sizing: border-box;
}

.inactive {
    margin-left: 80px;
}

.MuiDialog-paperWidthMd {
    max-width: 960px !important;
    max-height: 800px !important;
    width: 500px !important;
    align-items: center !important;
}

.MuiDialog-container form {
    flex-direction: column;
}

.btn {
    width: auto;
    height: 35px;
    border: none;
    background-color: #036ddf;
    color: white;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    min-width: 125px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;

}

.btn:hover {
    opacity: .8;
}


.btn:disabled,
.btn[disabled]:hover {
    opacity: 0.5;
    cursor: auto;
}

.btn-outlined {
    background-color: transparent;
    border: 1px solid #036ddf;
    color: #036ddf;
}

.btn-green {
    background-color: #03a202;
}

.btn-violet {
    background-color: #8C3BBB;
}

.btn-red {
    background-color: var(--red);
}


.btn-grey {
    background-color: var(--grey);

}


.btn-trans {
    background-color: transparent;
    color: var(--font-color-1);
    margin: 0;
    opacity: 0.8;
    box-shadow: none;
    height: auto;
}

div[class*="-MenuPortal"] {
    z-index: 500 !important;
}

.font-display-bold { 
    font-family: 'Johnson Display', 'Open Sans', sans-serif;
    font-weight: 700;
}

.font-display-med {
    font-family: 'Johnson Display', 'Open Sans', sans-serif;
    font-weight: 500;
}

.font-display-reg { 
    font-family: 'Johnson Display', 'Open Sans', sans-serif;
    font-weight: 400;
}

.font-display-light { 
    font-family: 'Johnson Display', 'Open Sans', sans-serif;
    font-weight: 300;
}