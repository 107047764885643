.side-menu {
    position: fixed;
    width: 80px;
    height: 100vh;
    background: #000;
    box-sizing: border-box;
    padding: 30px 20px;
    transition: all ease-in-out 0.5s;
    z-index: 99;
}

.menu-open {
    width: 300px;
}

.menu {
    color: #7a7474;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.5s linear;
}

.menu-item.active {
    background: #333;
    color: #fff;
}

.menu-item {
    background-color: transparent;
    color: #7a7474;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    width: 100%;
    text-align: left;
    padding-left: 25px;
    border-radius: 5px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-item span {
    margin-right: 15px;
    display: flex;
}

.menu-item:hover {
    background-color: #c5c5c5;
    color: black;
}

.main-menu {
    margin: 5px 0;
    position: relative;
    max-height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;
}

.sub-menu {
    width: 100%;
    height: fit-content;
    transition: all 0.5s linear;
    color: #333;
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
}

.sub-closed {
    height: 0;
    overflow: hidden;
}

.sub-menu a {
    border-radius: 5px;
    box-sizing: border-box;
    color: #b0a0a0;
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin: 5px 0;
    padding: 5px;
    text-decoration: none;
}

.sub-menu a:hover {
    background-color: #c5c5c5;
    color: black;
}

.sub-menu a.active {
    background: #222;
    display: block;

    color: #fff;
}

.top-section {
    position: relative;
    height: 40px;
}

.divider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
}



.logo {
    overflow: hidden;
    display: inline-block;
    width: 260px;

}

.logo-closed {
    overflow: hidden;
    display: inline-block;
    width: 40px;
}

.side-menu.menu-open .main-menu {
    display: block;
}

.logo img {
    max-width: 100%;
    max-height: 100%;
    height: 36px;
}

.toggle-menu-btn {
    color: rgb(133 133 133);
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.5s linear;

}

.side-menu.menu-open .toggle-menu-btn {
    right: 0px;
}



.side-menu .search-controller {
    color: #fff;
    position: relative;
}

.side-menu .search-controller .search-btn {
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #666;

}

.side-menu .search-controller input[type=text] {
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
}