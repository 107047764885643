.dba-container {
    padding: 30px;
    background-color: white;
    margin: 10px 50px;
}

.dba-heading,
.tableData-box h2 {
    font-size: 16px;
    margin-bottom: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.75;
    letter-spacing: 0.02857em;
    color: #212529;
}

.dba-text,
.tableData-box ul {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;   
    color: rgba(0, 0, 0, 0.87);
}

.tableData-container {
    display: flex;
    gap: 10px;
    gap: 10px 20px;    
    row-gap: 10px;
    column-gap: 20px;
    margin-top: 30px;
    flex-wrap: wrap;
}

.tableData-box {
    flex-grow: 4;   
    border-collapse: collapse;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    overflow: hidden;
    width: 250px;
}

.tableData-container .tableData-box h2 {
    margin-bottom: 0px;
    text-align: center;
    color: white;
}

.heading-box {
    background-color: #387bff;
    padding: 10px;
    position: relative;
}

.heading-item {
    border: 1px solid;
    margin-bottom: 10px;
    padding: 6px;
}

.heading-inner div:first-child { 
    font-weight: 600;
}

.box-items {
    border-bottom: 1px solid #dfdfdf;
    font-size: 0.75rem;
    position: relative;
    padding: 6px;  
}

.box-items:nth-child(even) {
    background-color: rgb(245, 249, 255);
}

.box-items span {
    font-weight: 700;
}

button.copy-btn {
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;
    background: #f1f1f1;
}
button.run-btn{
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;
    background: #f1f1f1;
}

li.box-items.margin {
    padding: 10px;
}

li.box-items.margin p {
    margin-top: 10px;
}

.heading-padding {
    padding: 6px;
}
.no-data{
    background-color: bisque;
    padding: 20px;
    margin-top: 25px;
}
.dba-error{
    margin-top: 20px;
}
section.info-msg {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
}
section.info-msg div{
    padding:44px;
}

.job-admin-container {
    padding: 50px;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;   
    text-align: left;
    background-color: #fff;   
    position: relative;
}
