.file-manager-container {
    display: flex;
    box-sizing: border-box;
}

.file-breadcrumbs {
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    height: 40px;
    padding-left: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.file-list {
    flex-grow: 1;
    box-sizing: border-box;
}

.view-log-page {
    margin: 20px;
}

.sidebar {
    min-width: 180px;
    height: calc(100vh - 134px);
    overflow-y: auto;
    background-color: #f8f8f8;
    border-right: 1px solid #ddd;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: inset 0 0 5px #d9d9d9;
}

.sidebar div {
    padding: 3px 0;
    box-sizing: border-box;
}

.sidebar span {
    user-select: none;
    box-sizing: border-box;
}

.sidebar .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.sidebar .folder-name {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.sidebar-folder {
    display: grid;
    align-items: center;
    cursor: pointer;
    grid-template-columns: 25px 34px 1fr;
    box-sizing: border-box;
}


.file-list-table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
}

.file-list-header,
.file-list-item {
    display: grid;
    grid-template-columns: 2fr 0.5fr 1fr 0.5fr;
    padding: 8px;
    border-bottom: 1px solid #dddddd87;
    box-sizing: border-box;
    height: 40px;
    padding-left: 16px;
}

.file-list-item {
    font-family: monospace;
}

.file-list-header {
    background-color: #f9f9f9;
    font-weight: bold;
    box-sizing: border-box;
    margin-right: 9px;
}

.file-name {
    display: flex;
    align-items: center;
    gap: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.file-name span {
    cursor: default;
}

.file-list-item:hover {
    background-color: #f0f0f0;
}

.file-list-empty {
    padding: 20px;
    text-align: center;
}

.file-list-container {
    height: calc(100vh - 174px);
    overflow-y: scroll;
    background: #f9f9f9;
    box-shadow: inset 0 0 5px #d9d9d9;
}

.file-list-footer {
    height: 30px;
    border: 1px solid #8080805e;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    gap: 7px;
    font-family: monospace;
    color: #565656;
    justify-content: space-between;
}

.file-breadcrumbs-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-right: 5px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.file-breadcrumbs-wrap:hover {
    background-color: rgb(76 89 104 / 8%);
}

.view-log {
    flex-grow: 1;
    margin: 1vw;
}