.date-picker-icon {
    top: 10px;
    position: absolute;
    right: 16px;
}


.date-picker-input {
    font-family: monospace;
    padding-right: 14px;
    border: 1px solid var(--border-color-1);
    border-radius: 5px;
    font-size: 14px;
    height: 38px;
    text-align: center;
    width: 150px;
}

.react-datepicker__current-month {
    display: none !important;
}

.react-datepicker__day--outside-month {
    color: #959595 !important;
}

.react-datepicker {
    box-shadow: 0 0 4px 3px rgb(54 54 54 / 27%), 0 1px 3px -1px rgb(221 221 221 / 26%) !important;
}

.react-datepicker__year-option:not(:first-child):not(:last-child) {
    width: calc(25%) !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 40px !important;
    line-height: 0px !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    width: 232px !important;
    height: 195px !important;
    left: -1px !important;
    top: 57px !important;
    align-items: center !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #fff !important;
    padding: 4px !important;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
    background: #bad9f1 !important;
    border-radius: 0 !important;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {
    display: none !important;
}

.react-datepicker__year-option:first-child,
.react-datepicker__year-option:last-child {
    width: 100% !important;
    height: 25px !important;
    justify-content: center !important;
    align-items: center !important;
    display: none !important;
}

.react-datepicker__year-option:nth-last-child(2) {
    display: none !important;
}

.react-datepicker__navigation--years-upcoming {
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 10px solid #7e7e7e !important;
    top: 0 !important;
}

.react-datepicker__navigation--years-previous {
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 10px solid #7e7e7e !important;
    top: 0 !important;
}

.react-datepicker__month-option {
    width: calc(33.33%) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 40px !important;
    line-height: 0px !important;
    border-radius: 0 !important;
}

.react-datepicker__month {
    height: 190px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-around !important;
}

.react-datepicker__day--selected {
    background-color: #387bff !important;

}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
    background-color: #f0f0f0 !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
    visibility: visible !important;
    font-weight: 600 !important;
}