.dashboard-main {
  position: relative;
  display: grid;
  width: 100%;
  justify-items: center;

}

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  position: absolute;
  width: 1108px;
  top: 50px;
  gap: 7rem;
}

.dashboard-group-container {}

.dashboard-group-product {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dashboard-group-product:hover {
  background-color: #9797974d;

}

.dashboard-product-workbook {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: fit-content;
}

.dashboard-product-workbook:hover {
  font-style: italic;
  text-decoration: underline;
}

.d-selected {
  background-color: #9797974d;
  border-radius: 5px;
}

.dashboard-workbook-cont {
  margin-left: 1rem;
  font-size: 14px;
  display: flex;
  flex-direction: column;

}

.dashboard-product-cont {
  min-width: 300px;
}

.dashboard-product-title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.dashboard-link-icon {
  display: flex;
  align-items: center;
}

.dashboard-product {
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dashboard-product div {
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 8px;
}

.dashboard-product:hover div {
  background-color: #97979728;

}

.dashboard-link-icon {
  height: 38px;
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  border-radius: 5px;
  opacity: 0.3;
}

.dashboard-link-icon:hover {
  background-color: #97979728;
  opacity: 1;
}

.dashboard-main-container {
  height: calc(100vh - 64px);
  display: grid;
  align-items: center;
  align-content: center;
}

.homepage-dashboard-cont {
  position: relative;
  width: 1187px;
}

.dashboard-product-wrapper {
  height: 550px;
  overflow-x: scroll;
}

@media (min-height: 1100px) and (min-width: 2000px) {
  .dashboard-main img {
    scale: 1.3;
    transition: all 0.2s ease-out;
  }

  .dashboard-container {
    scale: 1.3;
  }

  .homepage-dashboard-cont {
    scale: 1.3;
  }

  .homepage-dashboard-link {
    top: 0px;
  }
}

@media (max-height: 850px),
(max-width: 1300px) {
  .dashboard-main img {
    scale: 0.7;
    transition: all 0.2s ease-out;
  }

  .dashboard-container {
    scale: 0.8;
    top: 90px;
    width: 920px;
  }

  .homepage-dashboard-cont {
    scale: 0.8;
    width: 920px;
  }

  .homepage-dashboard-link {
    top: -200px;
    left: 135px;
  }

  .dashboard-product-wrapper {
    height: 500px;
  }
}