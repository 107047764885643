.custom-form-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

}

.custom-form-label {
    font-size: 15px;
    font-weight: 600;
}

.custom-form-input {
    border: 1px solid var(--border-color-1);
    border-radius: 4px;
    width: 100%;
    height: 36px;
    padding-left: 5px;
    box-sizing: border-box;
}

.custom-form-textarea {
    border: 1px solid var(--border-color-1);
    border-radius: 5px;
    min-height: 70px;
    padding: 10px;
    resize: vertical;
    width: 95%;
}

.custom-form-checkbox {
    height: 18px;
    margin: 10px;
    width: 18px;
    cursor: pointer;
}