@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

.grid-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

.datagrid-form table {
  border-collapse: collapse;
  width: 70%;
}

.datagrid-form tr:hover {
  background-color: rgb(144, 197, 254);
}

.datagrid-form th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 16px;
}

.datagrid-form th {
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  color: #ffffff;
  background-color: #036ddf;
  font-family: "Montserrat", sans-serif;
}

.odd-tr {
  background-color: rgb(233, 244, 251);
}

.even-tr {
  background-color: rgb(205, 235, 253);
}

.datagrid-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 300px;
}

.datagrid-form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

.datagrid-form * {
  font-size: 16px;
}

.action-icon {
  padding-left: 5px;
}

.hover-pointer {
  cursor: pointer;
}

.add-table-item {
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 5px;
  color: white;
  background-color: #036ddf;
  width: 125px;
  text-align: center;
  padding: 5px;
}

.table-btn-container {
  display: flex;
  gap: 1rem;
}

.csv-link {
  height: 35px;
  border: none;
  background-color: #036ddf;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 125px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.csv-link:hover {
  opacity: 0.8;
  background-color: #036ddf;
}

.csv-link:active {
  opacity: 1;
}


/* form css are added here */

.form-input {
  border: 1px solid hsl(0, 0%, 80%);
  width: 100%;
  border-radius: 5px;
  height: 38px;
  padding-left: 5px;
  box-sizing: border-box;
}

.table-name {
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 50px
}