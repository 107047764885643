.survey-container {
    padding: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.survey-page {
    display: grid;
    grid-template-columns: .8fr 1fr;
    gap: 3rem;
}

.survey-top-title {
    margin: 0 0 10px 0;
}

.survey-top-title p {
    font-weight: 600;
    margin-bottom: 5px;
}

.survey-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.survey-link:hover {
    color: blue;
}

/* survey response page */

.survey-select-container {
    display: flex;
    flex-direction: column;
    width: 640px;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.survey-select-top-row {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.survey-select-div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.admin-survey {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: column;
    padding: 2rem;
    padding-top: 1rem;
}

.admin-survey-top-btns {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
}

/* form */



.admin-form-container {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    gap: 2rem;
}

.admin-form {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.rf-select-placeholder-div {
    height: 38px;
    border-radius: 4px;
    border: 1px solid var(--jnj-border);
    display: flex;
    justify-content: center;
    background-color: white;
    min-width: 300px;
}

.admin-template-select {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.survey-page-container {
    padding: 2rem;
}

.info-active {
    color: #0F68B2;
}

.info-inactive {
    color: #FF6017;
}

.css-b62m3t-container {
    min-width: 300px;
}

.survey-table-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.survey-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.admin-form-select-cont {
    display: flex;
    gap: 3rem;
}

.admin-category-edit {
    opacity: 0.8;
    display: flex;
    color: #102b3f;
}

.admin-category-edit:hover {
    opacity: 1;
    cursor: pointer;
}

.admin-form-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey-admin-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 600px;
    justify-content: space-between;
}

.admin-btn-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

@media (max-width: 1400px) {
    .survey-page {
        grid-template-columns: 1fr;
    }
}

/* Answers table */
.admin-answers-table {
    border-collapse: collapse;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    overflow: hidden;
    width: 800px;
    font-size: 15px;

}

.admin-answers-table thead {
    background-color: #1A535C;
    color: white;
    border: 1px solid #1A535C;
    height: 50px;
}

.admin-answers-table th {
    padding: 5px;
}

.admin-answers-table tbody {
    background-color: #ffffff;
}

.admin-answers-table td {
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    font-size: 15px;
    text-align: center;
}


.admin-answers-table tr:nth-child(even) {
    background-color: #2d585e0a;
}

.survey-divider {
    align-items: center;
    background-color: #273949;
    color: #fff;
    display: flex;
    font-size: 1.2em;
    min-height: 40px;
    padding: 2px 2px 2px 1rem;
    border-radius: 5px 5px 0 0;
}

.survey-section-wrapper {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    background: #fff;
    border-radius: 5px;
}

.access-denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    color: #333;

}

.access-denied h2 {
    font-size: 2rem;
    color: #dc3545;
    margin-bottom: 1rem;
}

.access-denied p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 2rem;
}