.MuiButtonBase-root {
    text-decoration: none !important;
}

hr {
    height: 10px;
    background-color: midnightblue;
    border: none;
}

.MuiContainer-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.MuiGrid-root.MuiGrid-grid-md-2 {
    border-right: 5px double rgb(174, 176, 176) !important
}

.iframe-content {
    margin-left: 16px;
}

#topMenu>div>div {
    align-items: center;
}

/* width */
::-webkit-scrollbar {
    width: 9px !important;
    height: 10px;
}


.container-dashboard {
    /* Add styles for your container */
    padding: 1rem;
}

.main-tabs {
    display: flex;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
}

.mylinks-save-icon {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.mylinks-save-disabled {
    opacity: 0.5;
    cursor: default;
    display: none;
}

.mylinks-save-inactive {
    opacity: 0.5;
}

.main-tab-dashboard {
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: 15px;
}

.main-tab-dashboard.active {
    border-bottom: 3px solid var(--blue);
    color: var(--blue);
    cursor: auto;
}

.main-tab-dashboard:hover {
    color: var(--blue);
}

.content {
    display: flex;
    margin-top: 16px;
}

.tableau-report {
    flex: 1;
}

.tableau-report.full-width {
    flex: 1 0 100%;
}

.secondary-tab {
    display: block;
    padding: 8px 16px;
    text-align: left;
    background-color: transparent;
    border: none;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
}



.secondary-tab:hover {
    color: var(--blue);

}

.secondary-tab.active {
    color: var(--blue);
    border-right: 3px solid var(--blue);
    cursor: auto;
}

.side-menu-dashboard {
    width: 0px;
    border-right: 1px solid var(--border-color-1);
    position: relative;
}

.side-menu-open {
    min-width: 220px;
}

.side-menu-open-close {
    opacity: 0.5;
    position: absolute;
    right: 6px;
    top: 0px;
}

.side-menu-open-close-right {
    right: -27px;
    z-index: 99;
}

.side-menu-open-close:hover {
    opacity: 1;
    cursor: pointer;
}


.info-msg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.msr-popup .customPopup_content__swCWu {
    overflow: visible;
}

.datagrid-form input {
    font-size: 16px;
    line-height: 36px;
    width: 100%;
    padding: 2px 8px;
    box-sizing: border-box;
}