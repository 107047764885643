.mb-1 {
  margin-bottom: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.MuiDialogTitle-root.makeStyles-dialogTitle-2>h2 {
  text-align: center;
}

.data-exp-tables {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.data-exp-container {
  padding: 50px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  height: calc(100vh - 185px);
}

.data-exp-table {
  padding: 5px;
}

.btn-icon {
  opacity: 0.8;
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.btn-icon:hover {
  opacity: 1;
  cursor: pointer;
  font-size: 20px !important;
}

.btn-icon:nth-child(1):hover {
  color: green;
}

.btn-icon:nth-child(2):hover {
  color: blue;
}

.btn-icon:nth-child(3):hover {
  color: green;
}

.btn-icon:nth-child(4):hover {
  color: orange;
}

.btn-icon:nth-child(5):hover {
  color: red;
}

.btn-disabled {
  opacity: 0.4;
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.data-exp-popup div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}

.data-exp-popup h2 {
  font-weight: 400;
}