.audit-container {
    padding: 2rem;
    padding-top: 1rem;
    margin-left: '32px';
    margin-right: '32px';
}

.audit-input-container {
    display: flex;
    flex-direction: column;
    width: 640px;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.audit-select-div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.audit-input-top-row {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.audit-input-second-row {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.audit-search-item {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: lightgray;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1px;
    box-shadow: 0 0 2px black;
    align-items: center;
    gap: 10rem;
    width: fit-content;
    min-width: 50%;
}

.audit-details-header {
    gap: 1rem;
    margin-bottom: 1rem;
}

.audit-search-item-pkey-section {
    display: flex;
    flex-direction: column;
    min-width: 64px;
}

.audit-search-item-pkey {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.audit-search-item-dv-section {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.bold {
    font-weight: bold;
}

.audit-search-cols {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: 0.5rem;
    width: 100%;
    margin-top: 6px;
}