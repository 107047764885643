.data-mart-container {
    padding: 50px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;   
    text-align: left;
    background-color: #fff;   
    /* height: 80vh; */
  }
.table_mart{
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2), 0 0 1px rgba(0,0,0,.4);
    border-collapse: collapse;  
    width: 100%;
   
}
.table_mart th{
    padding: 10px 30px;
    background-color: #b8daff;
    color: #212529;
    border: 1px solid grey;
    width: 113px;
}
 .table_mart td{
    padding: 8px 50px 8px 30px;
    border: 1px solid grey;
    position: relative;
}
.table_mart-desc{
    height:63px;
}
.table_mart caption {
    text-align: left;
    position: relative;
}
.table_mart caption span {
    position: absolute;
    right: 0px;
    top: 22px;
    cursor: pointer;
    &:hover {
        color: green;
    }
}

.data-mart-tables {   
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}

.data-mart-container h2{
    margin: 10px 0px;
}

.data_mart-info-table-wrapper {
    position: relative;
    margin-top: 40px;
}

.data_mart-info-table-wrapper .table_table-header-actions__SgOEw {   
    position: absolute;
    right: 0px;
    top: 0px;
}
.table_table-search-input__eRRXf {
    width: 500px;
    font-size: 16px;
}

.info-form_edit-box {
    position: absolute;
    background-color: white;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 9;
}
.info-form-text input[type="text"], .info-form-text textarea {
    padding: 11px 30px;
    font-size: 16px;
    width: -webkit-fill-available;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.info-form-text textarea {
    vertical-align: top;
    resize: none;
}
.info-form-text {
    float: left;
    border: 5px solid lightblue;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.info-form-btns {
    width: 80px;
    position: absolute;
    right: 0px;
    top: -42px;
}
.info-form-btns svg{
    padding: 10px;    
    cursor: pointer;
    &:hover {
        color: green;
    }
}
.info-form-btns .info-form-btn-close{
    float: right;
    font-size: 27px;
    padding: 10px;
    font-weight: bold;
    line-height: 15px;
    cursor: pointer;
    &:hover {
        color: green;
    }
}
.info-form-text .loading_loadingWrapper__LZ\+bE {
    position: absolute;
    top: -5px;
    right: 3px;
    height: 20px;
    width: 40px;
}
.info-form-text .loading_loader__gKuBU{
    width: 20px !important;
    height: 20px !important;  
    border-width: 2px;
    border-color: #333 #333 transparent;
}
.ts-btn{
    background-color: #036ddf;
    padding: 10px;
    color: #fff;
    border: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 44px;
}
.ts-btn span{
    margin: 0px 6px;
}

.ts-btn.green{
    background-color: transparent;
    position: absolute;
    right: 52px;
    top: 13px;
    color: black;
    border: 1px solid #97979763;
    height: 37px;
    
}
.ts-btn.green:hover{
    background-color: #97979763;
}
.job-admin-container .table_table-header-actions__SgOEw{
    position: absolute;
    right: 172px;
    top: 14px;
}