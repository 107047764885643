.custom-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.MuiDialog-container>.MuiPaper-root.MuiDialog-paper {
    min-width: 95% !important;
    max-width: 95% !important;
    height: 100% !important;
}

.popup-footer {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.myLinkDataForm-search {
    display: flex;
    align-items: center;
    height: 36px;
    font-weight: 500;
    gap: 1rem;
}

.makeStyles-dialogWrapper-1 {
    top: 0px !important;
}

.MuiDialogTitle-root {
    padding: 0px !important;
}

.MuiDialogContent-dividers {
    padding: 0px 16px 24px;
}

.filterWidth {
    width: 33%;
    margin-top: 10px;
}

.myLinkDataForm-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.myLinkDataForm-header p {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.myLinkDataForm-btns {
    display: flex;

    align-items: center;
    gap: 1rem;
}

.myLinkDataForm-input {
    height: 35px;
    padding-left: 5px;
    border: 1px solid #c7c7c7c4;
    border-radius: 4px;
    width: 250px;
}

.myLinkDataForm-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1rem;
}

.myLinkDataForm-column-box {
    display: flex;
    flex-direction: column;
    height: 250px;
    padding: 3px 0;
    overflow: hidden scroll;
    width: 100%;
    align-items: center;
    gap: 5px;
}

.myLinkDataForm-column-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.myLinkDataForm-title-box {
    text-align: center;
    margin-bottom: 17px;
    padding: 5px 0;
    background-color: #dcdcdc;
    font-weight: 500;
}

.myLinkDataForm-input-label {
    font-weight: 500;
    padding: 3px 0;
    margin-bottom: 5px;
}

.myLinkDataForm-filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}



.myLinkDataForm-btn-icons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.myLinkDataForm-input-checkbox:hover {
    cursor: pointer;
}

.myLinkDataForm-date {
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    text-align: center;
    width: 150px;
    border: 1px solid var(--border-color-1);
}

.myLinkDataForm-date-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.myLinkDataForm {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
}

.myLinkDataForm-date-wrap p {
    font-weight: 500;

}
#btn-generate{
    background-color: green;
    :hover{
        color: white;
    }
}
#btn-generate:hover{  
        color: white;
}
.msrDatePicker .react-datepicker__current-month{
    display: block !important;
}