.header-action-wrap {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.header-dot-menu {
    width: 10px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    align-items: center;
    padding: 5px 1rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 300;
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .28);
    height: 64px;
}

.mylink-menu-actions {
    gap: 15px;
    padding: 0.275rem 1rem !important;
    font-size: 0.9em;
}

.mylink-container {
    padding-left: 15px;
    padding-right: 15px;
}

.mylink-menu-title {
    font-size: 1.1em;
    font-weight: 600;
    padding-bottom: 5px;
    display: block;
    padding-top: 15px;
}

.mylink-menu-item {
    padding-left: 15px;
    font-size: 0.9em;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
}

.mylink-menu-item:hover {
    background-color: #6dacff25;
}

.mylink-item-name {
    font-size: 0.9em;
    cursor: pointer;
}

.mylink-item-name:hover {
    font-weight: 600;
}

.separator-menu {
    border-bottom: 1px solid #d0d7de;
    padding-top: 0.5rem;
}

.header-nav {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-menu-btn {

    font-family: 'Montserrat', sans-serif;
    font-size: 16px;

}

.header-menu-btn:hover {
    color: #515115
}

.menu-action-wrap {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;

}

.menu-action-wrap:hover {
    font-weight: 600;
    cursor: pointer;
}

.data-link-popup {
    display: flex;
    flex-direction: column;

}

.data-link-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5px;
}

.data-link-rename {
    height: 35px;
    padding-left: 5px;
    border: 1px solid #c7c7c7c4;
    border-radius: 4px;
    width: 370px;
    margin-bottom: 1rem;
}

.mylink-default {
    font-size: 90%;
    line-height: 1;
    margin: 0px 5px;
    color: rgb(53 53 53);
    font-style: italic;
    font-weight: 700;
}

.mylink-sub-menu-title {
    font-size: 0.8rem;
    opacity: 0.7;
}

.mylink-icon-badge {
    position: absolute;
    top: -10px;
    font-size: 0.6rem;
    right: 5px;
    color: white;
    background: #0574d8;
    border-radius: 5px;
    padding: 0 5px 2px 5px;
}